export const categoryMapping = [
  {
    label: 'University',
    fields: [
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'institute',
        label: 'Institute',
      },
      {
        name: 'institutionType',
        label: 'Institution Type',
      },
    ],
  },
  {
    label: 'School',
    fields: [
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'institute',
        label: 'Institute',
      },
      {
        name: 'institutionType',
        label: 'Institution Type',
      },
    ],
  },
  {
    label: 'Visitors',
    fields: [
      {
        name: 'checkedIn',
        label: 'Checked In',
      },
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'emiratesId',
        label: 'Emirates ID',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'checkInTime',
        label: 'Check In Time',
      },
    ],
  },
  {
    label: 'Coaches',
    fields: [
      {
        name: 'checkedIn',
        label: 'Checked In',
      },
      {
        name: 'name',
        label: 'Name',
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'emiratesId',
        label: 'Emirates ID',
      },
      {
        name: 'phone',
        label: 'Phone',
      },
      {
        name: 'institute',
        label: 'Institute',
      },
      {
        name: 'sport',
        label: 'Sport',
      },
      {
        name: 'vehicleNumber',
        label: 'Vehicle Number',
      },
      {
        name: 'checkInTime',
        label: 'Check In Time',
      },
    ],
  },
];

export const playerMapping = [
  {
    name: 'playerType',
    label: 'Player Type',
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'emiratesId',
    label: 'Emirates ID',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
  {
    name: 'age',
    label: 'Age',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
  },
  {
    name: 'signUrl',
    label: 'Undertaking Sign',
  },
  {
    name: 'checkInTime',
    label: 'Check In Time',
  },
];
