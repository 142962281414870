import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { collection, onSnapshot } from 'firebase/firestore';

import { firestore } from '../services/firebase';
import { toTitleCase } from '../helper/helper_functions';
import InstitutesTable from '../components/bsf/InstitutesTable';

const BSFRegistrationTypes = () => {
  const currentYear = new Date().getFullYear();
  let localData = [
    {
      id: 'universities',
      title: 'Universities',
      type: 'university',
      dbPath: `registrations/${currentYear}/bsf/institutes/registrations`,
    },
    {
      id: 'schools',
      title: 'Schools',
      type: 'school',
      dbPath: `registrations/${currentYear}/bsf/institutes/registrations`,
    },
    {
      id: 'visitors',
      title: 'Visitors',
      type: 'visitors',
      dbPath: `registrations/${currentYear}/bsf/visitors/registrations`,
    },
    {
      id: 'coaches',
      title: 'Coaches',
      type: 'coaches',
      dbPath: `registrations/${currentYear}/bsf/coaches/registrations`,
    },
  ];

  const { registrationType } = useParams();
  const [institutions, setInstitutions] = useState([]);

  const parsedRegistrationType = localData.find(
    (registrationTypeObject) => registrationTypeObject.id === registrationType
  )?.type;
  const dbPath = localData.find(
    (registrationTypeObject) => registrationTypeObject.id === registrationType
  )?.dbPath;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(firestore, dbPath);
        const unsubscribeUser = onSnapshot(usersCollection, (querySnapshot) => {
          const localInstitutions = [];
          querySnapshot.forEach((doc) => {
            const i = doc.data();
            if (parsedRegistrationType === 'visitors') {
              localInstitutions.push(i);
            } else if (parsedRegistrationType === 'coaches') {
              localInstitutions.push(i);
            } else if (
              i.institutionType?.toLowerCase() ===
              parsedRegistrationType?.toLowerCase()
            ) {
              localInstitutions.push(i);
            }
          });

          setInstitutions(localInstitutions);
        });

        return () => {
          unsubscribeUser();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchUsers();
  }, [registrationType, parsedRegistrationType, dbPath]);

  return (
    <Container size='4' align='center' className='mb-5 md:mb-9'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '6', md: '9' }}
        className='text-center'
      >
        <h1 className='text-xl md:text-3xl font-bold'>
          {toTitleCase(parsedRegistrationType)}
        </h1>

        <InstitutesTable
          institutesData={institutions}
          registrationType={parsedRegistrationType}
        />
      </Flex>
    </Container>
  );
};

export default BSFRegistrationTypes;
