import { Link } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import React, { Suspense, useEffect, useState } from 'react';

import Button from './Button';
import Loading from './Loading';
import { analytics } from '../services/firebase';
import { getDecryptedItem } from '../helper/helper_functions';

function Layout({ children }) {
  const [session, setSession] = useState(false);
  logEvent(analytics, 'website_start');

  useEffect(() => {
    const userName = getDecryptedItem('user_session');
    if (userName && userName !== '') {
      setSession(true);
    } else {
      setSession(false);
    }
  }, []);

  return (
    <React.Fragment>
      <main className='min-h-dvh w-screen flex flex-col justify-between gap-5 md:gap-10 bg-[#fdfdfd]'>
        <Link to='/' className='absolute z-10 left-3 md:left-5 top-5 md:top-6'>
          <img
            src='/assets/logos/BITSLogo.png'
            alt='logo'
            className=' h-auto w-[120px] md:w-[150px]'
          />
        </Link>

        {session && (
          <Button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            className='absolute z-10 right-3 md:right-5 px-6 md:px-12 top-5 md:top-6 bg-[#B6554F] text-sm md:text-lg'
            title='LOGOUT'
          />
        )}

        <Suspense fallback={<Loading />}>
          <div className='h-full pt-20 md:pt-8 px-5'>{children}</div>
        </Suspense>
      </main>
    </React.Fragment>
  );
}

export default Layout;
