import { toast } from 'sonner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, TextField } from '@radix-ui/themes';

import Button from '../components/Button';
import { Users } from '../helper/users';
import { setEncryptedItem } from '../helper/helper_functions';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    userName: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLoginSubmit = () => {
    const user = Users.find((user) => user.userName === formData.userName);
    if (user.password === formData.password) {
      toast.success('Logged In!');
      setEncryptedItem('user_session', formData.userName);
      navigate('/', { replace: true });
      return true;
    }
    toast.error('Incorrect Username or Password');
    return false;
  };

  return (
    <Flex
      align='center'
      justify='center'
      direction='column'
      className='h-dvh -mt-20 md:-mt-8'
    >
      <Flex
        gap='7'
        align='center'
        justify='center'
        direction='column'
        className='w-auto p-10 md:p-20 bg-[#fdfdfd] rounded-2xl shadow-lg md:rounded-3xl md:shadow-2xl bg-opacity-40 backdrop-filter backdrop-blur-xl'
      >
        <h1 className='text-2xl md:text-4xl text-center font-semibold'>
          BITS Dubai Admin
        </h1>

        <Flex direction='column' align='center' gap='5'>
          <TextField.Root
            size='3'
            variant='surface'
            name='userName'
            onChange={handleChange}
            placeholder='Username'
            className='bg-transparent border-b-2 border-[#fdfdfd] w-full md:w-80'
          />
          <TextField.Root
            size='3'
            type='password'
            variant='surface'
            name='password'
            onChange={handleChange}
            placeholder='Password'
            className='bg-transparent border-b-2 border-[#fdfdfd] w-full md:w-80'
          />
        </Flex>

        <Button
          title='LOGIN'
          onClick={handleLoginSubmit}
          className='bg-[#050505] text-[#fdfdfd] rounded-xl px-14 md:px-16'
        />
      </Flex>
    </Flex>
  );
};

export default Login;
