import { toast } from 'sonner';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button, Table } from '@radix-ui/themes';

import { playerMapping } from '../../constants/bsf';
import { checkInBSFPlayer } from '../../helper/attendance';
import { toTitleCase } from '../../helper/helper_functions';

const SportsTable = ({ sportsData, institute }) => {
  const addSignUrls = () => {
    const updatedSportsData = {};

    Object.keys(sportsData).forEach((sport) => {
      const signUrl = institute?.[`${sport}-sign-url`];

      updatedSportsData[sport] = sportsData[sport].map((player) => ({
        ...player,
        signUrl: signUrl,
      }));
    });

    return updatedSportsData;
  };

  const handleCheckIn = async (sportName, playerName, emiratesId) => {
    try {
      const { status, message } = await checkInBSFPlayer(
        institute?.institute,
        sportName,
        playerName,
        emiratesId
      );
      if (!status) throw new Error(message);
      toast.success(message);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(`Something went wrong, ${error.message}`);
      console.error(error);
    }
  };

  const updatedSportsData = addSignUrls(sportsData, institute);

  return (
    <div className='w-full h-full'>
      <div className='flex flex-col gap-4 md:gap-6 items-center w-full'>
        {Object.keys(updatedSportsData).map((sport, index) => (
          <div
            key={index}
            className='flex flex-col gap-2 md:gap-4 items-center w-full'
          >
            <h1 className='text-xl md:text-2xl font-semibold text-center'>
              {sport.replace('-', ' ').toUpperCase()}
            </h1>

            <Table.Root size='3' variant='surface' className='w-full'>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell className='text-sm'>
                    Check In
                  </Table.ColumnHeaderCell>
                  {playerMapping.map((field, index) => (
                    <Table.ColumnHeaderCell key={index} className='text-sm'>
                      {field.label}
                    </Table.ColumnHeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {updatedSportsData[sport].map((user, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <Button
                        disabled={user?.checkedIn}
                        className='w-10 h-6'
                        variant='outline'
                        onClick={() => {
                          if (user.checkedIn) {
                            toast.error('User already checked in');
                            return;
                          }
                          handleCheckIn(sport, user?.name, user?.emiratesId);
                        }}
                      >
                        {user?.checkedIn && <Check color='green' />}
                      </Button>
                    </Table.Cell>
                    {playerMapping.map((field, index) => (
                      <Table.Cell key={index} className='text-sm'>
                        {!user?.[`${field.name}`] ? (
                          '--'
                        ) : field.name.toLowerCase().includes('sign') ? (
                          <Link
                            rel='noreferrer'
                            target='_blank'
                            className='text-blue-600'
                            to={user?.[`${field.name}`]}
                          >
                            Download
                          </Link>
                        ) : (
                          toTitleCase(user?.[`${field.name}`]?.toString())
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SportsTable;
