import { useEffect, useState } from 'react';
import { Container, Tabs } from '@radix-ui/themes';
import { collection, onSnapshot } from 'firebase/firestore';

import { Users } from '../helper/users';
import { firestore } from '../services/firebase';
import Dashboard from '../components/bsf/Dashboard';
import BSFScanner from '../components/bsf/BSFScanner';
import { getDecryptedItem } from '../helper/helper_functions';

const BSF = () => {
  const [allow, setAllow] = useState(null);
  const [schoolsData, setSchoolsData] = useState([]);
  const [universitiesData, setUniversitiesData] = useState([]);
  const [visitorsData, setVisitorsData] = useState([]);
  const [coachesData, setCoachesData] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const userName = getDecryptedItem('user_session');
    const user = Users.find((user) => user.userName === userName);
    setAllow(!user.scannerOnly);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const institutesCollection = collection(
          firestore,
          `registrations/${currentYear}/bsf/institutes/registrations`
        );
        const visitorsCollection = collection(
          firestore,
          `registrations/${currentYear}/bsf/visitors/registrations`
        );
        const coachesCollection = collection(
          firestore,
          `registrations/${currentYear}/bsf/coaches/registrations`
        );

        const unsubscribeInstitutes = onSnapshot(
          institutesCollection,
          (querySnapshot) => {
            const localSchools = [];
            const localUniversities = [];

            querySnapshot.forEach((doc) => {
              if (doc.data().institutionType === 'school') {
                localSchools.push(doc.data());
              } else {
                localUniversities.push(doc.data());
              }
            });

            setSchoolsData(localSchools);
            setUniversitiesData(localUniversities);
          }
        );
        const unsubscribeVisitors = onSnapshot(
          visitorsCollection,
          (querySnapshot) => {
            const localData = [];
            querySnapshot.forEach((doc) => {
              localData.push(doc.data());
            });

            setVisitorsData(localData);
          }
        );
        const unsubscribeCoaches = onSnapshot(
          coachesCollection,
          (querySnapshot) => {
            const localData = [];
            querySnapshot.forEach((doc) => {
              localData.push(doc.data());
            });

            setCoachesData(localData);
          }
        );
        return () => {
          unsubscribeInstitutes();
          unsubscribeVisitors();
          unsubscribeCoaches();
        };
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchData();
  }, [currentYear]);

  return (
    <Container size='4' align='center'>
      {allow !== null && allow ? (
        <Tabs.Root
          defaultValue='dashboard'
          className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'
        >
          <Tabs.List className='text-xl'>
            <Tabs.Trigger value='dashboard'>Dashboard</Tabs.Trigger>
            <Tabs.Trigger value='scanner'>Scanner</Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content value='dashboard' className='w-full h-full'>
            <Dashboard
              universitiesData={universitiesData}
              schoolsData={schoolsData}
              visitorsData={visitorsData}
              coachesData={coachesData}
            />
          </Tabs.Content>

          <Tabs.Content value='scanner' className='w-full h-full'>
            <BSFScanner />
          </Tabs.Content>
        </Tabs.Root>
      ) : (
        <div className='flex flex-col items-center w-full h-full gap-5 md:gap-9 mb-5 md:mb-9'>
          <h1 className='text-xl md:text-3xl font-bold'>Scanner</h1>

          <BSFScanner />
        </div>
      )}
    </Container>
  );
};

export default BSF;
