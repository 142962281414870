import { fetchDocumentByHashValue } from './firebase_functions';
import {
  arrayUnion,
  updateDoc,
  doc,
  query,
  getDocs,
  where,
  collection,
} from 'firebase/firestore';
import { firestore } from '../services/firebase';

export const checkSteamAttendance = async (hashedId, eventId) => {
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: 'steamregistrations',
  });

  if (documentData && documentData.events) {
    const { events, emiratesId } = documentData;
    const docRef = doc(firestore, `steamregistrations/${emiratesId}`);

    if (eventId === 'mainentrance') {
      await updateDoc(docRef, {
        attendance_done: arrayUnion(eventId),
      });
      return [true, `User is registered for STEAM@BITS2024`, documentData];
    }
    //TODO remove if we dont want single time pass
    // if (attendance_done && attendance_done.includes(eventId)) {
    //   return [true, "User has already attended this event", documentData];
    // }

    if (events.includes(eventId)) {
      await updateDoc(docRef, {
        attendance_done: arrayUnion(eventId),
      });
      return [
        true,
        `User is registered for ${eventId}. Attendance taken`,
        documentData,
      ];
    } else {
      return [
        false,
        `User found but no registration for ${eventId}`,
        documentData,
      ];
    }
  } else {
    return [false, 'Not registered for STEAM', documentData];
  }
};

export const checkJashnAttendance = async (hashedId, day) => {
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: 'registrations/2024/jashn',
  });

  if (documentData) {
    const { emiratesId } = documentData;
    const docRef = doc(firestore, `registrations/2024/jashn/${emiratesId}`);

    await updateDoc(docRef, {
      attendance_done: arrayUnion(day),
    });
    return [
      true,
      `User is registered for ${day}th. Attendance taken`,
      documentData,
    ];
  } else {
    return [false, 'Not registered for JASHN', {}];
  }
};
export const checkRDayAttendance = async (hashedId) => {
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: 'registrations/2024/rday',
  });

  if (documentData) {
    const { hashValue } = documentData;
    const docRef = doc(firestore, `registrations/2024/rday/${hashValue}`);

    await updateDoc(docRef, {
      attendance_done: true,
    });
    return [true, 'Attendance taken', documentData];
  } else {
    return [false, 'Not registered for Research Day', {}];
  }
};

export const checkInBSF = async (hashedId, type = 'visitors') => {
  const currentYear = new Date().getFullYear();
  const documentData = await fetchDocumentByHashValue({
    providedValue: hashedId,
    collectionPath: `registrations/${currentYear}/bsf/${type}/registrations`,
  });

  if (documentData) {
    const { emiratesId } = documentData;
    const docRef = doc(
      firestore,
      `registrations/${currentYear}/bsf/${type}/registrations/${emiratesId}`
    );

    const updatedDoc = {
      checkedIn: true,
      checkInTime: new Date().toISOString(),
    };

    await updateDoc(docRef, updatedDoc);

    return {
      status: true,
      message: 'Checked in successfully',
      data: documentData,
      updatedDoc: { ...documentData, ...updatedDoc },
    };
  } else {
    return { status: false, message: 'No user found', data: {} };
  }
};

export const getInstitutionType = async (name, playerName) => {
  const currentYear = new Date().getFullYear();
  const collectionPath = `/registrations/${currentYear}/bsf/institutes/registrations`;
  const institutesCollection = collection(firestore, collectionPath);
  const q = query(institutesCollection, where('institute', '==', name));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const institutionType = querySnapshot?.docs[0]?.data()?.institutionType;
    return institutionType === 'university' ? 'universities' : 'schools';
  }
  return null;
};

export const checkInBSFPlayer = async (
  instituteName,
  sportName,
  playerName,
  playerEmiratesId
) => {
  const currentYear = new Date().getFullYear();
  const collectionPath = `/registrations/${currentYear}/bsf/institutes/registrations`;
  const institutesCollection = collection(firestore, collectionPath);

  const q = query(
    institutesCollection,
    where('institute', '==', instituteName)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const document = querySnapshot.docs[0];
    const docId = document.id;
    const docData = document.data();

    const sportArray = docData.sports[sportName];

    if (sportArray && Array.isArray(sportArray)) {
      const playerIndex = sportArray.findIndex((player) => {
        return (
          player.name === playerName && player.emiratesId === playerEmiratesId
        );
      });

      if (playerIndex !== -1) {
        const checkInTime = new Date().toISOString();

        if (sportArray[playerIndex].checkedIn)
          throw new Error('Player already checked in');

        sportArray[playerIndex] = {
          ...sportArray[playerIndex],
          checkedIn: true,
          checkInTime: checkInTime,
        };

        const docRef = doc(firestore, `${collectionPath}/${docId}`);
        await updateDoc(docRef, {
          [`sports.${sportName}`]: sportArray,
        });

        return {
          status: true,
          message: 'Player checked in successfully',
          data: sportArray[playerIndex],
        };
      } else {
        return {
          status: false,
          message: 'Player not found in the specified sport',
          data: {},
        };
      }
    } else {
      return {
        status: false,
        message: 'Sport not found in the institute data',
        data: {},
      };
    }
  } else {
    return { status: false, message: 'Institute not found', data: {} };
  }
};
