export const Users = [
  {
    userName: 'BITSAdmin',
    password: 'Admin@BITS',
    permissions: ['/bsf', '/qbd', '/researchday', '/jashn', '/steam'],
  },
  {
    userName: 'BSFAdmin',
    password: 'bsf!Admin$67832',
    permissions: ['/bsf'],
    scannerOnly: true,
  },
  {
    userName: 'BSFSuperAdmin',
    password: 'bsf!SuperAdmin$69420',
    permissions: ['/bsf'],
  },
  {
    userName: 'QBDAdmin',
    password: 'qbd!Admin$37823',
    permissions: ['/qbd'],
  },
  {
    userName: 'ResearchDayAdmin',
    password: 'researchday!Admin$87892',
    permissions: ['/researchday'],
  },
  {
    userName: 'JashnAdmin',
    password: 'jashn!Admin$24892',
    permissions: ['/jashn'],
  },
  {
    userName: 'SteamAdmin',
    password: 'steam!Admin$77892',
    permissions: ['/steam'],
  },
];
