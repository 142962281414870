import { logEvent } from 'firebase/analytics';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Loading from '../components/Loading';
import { analytics } from '../services/firebase';
import { getDecryptedItem } from '../helper/helper_functions';
import { Users } from '../helper/users';
import { toast } from 'sonner';

const ProtectedRoute = ({ children }) => {
  let location = useLocation();
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(true);
  logEvent(analytics, 'website_start');

  useEffect(() => {
    const userName = getDecryptedItem('user_session');
    const user = Users.find((user) => user.userName === userName);

    var hasPermission = user?.permissions.some(
      (permission) =>
        location.pathname.startsWith(permission) || location.pathname === '/'
    );

    if (
      (location.pathname === '/bsf/schools' ||
        location.pathname === '/bsf/universities') &&
      user?.scannerOnly
    ) {
      hasPermission = false;
    }

    if (userName && userName !== '' && hasPermission) {
      setSession(true);
    } else {
      setSession(false);
    }

    if (!hasPermission)
      toast.error('You do not have permission to access this page');
    setLoading(false);
  }, [location.pathname]);

  if (loading) {
    return <Loading />;
  }

  if (!session) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
