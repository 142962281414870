import { toast } from 'sonner';
import { useState } from 'react';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button as RadixButton, Flex, Table } from '@radix-ui/themes';

import Button from '../Button';
import { categoryMapping } from '../../constants/bsf';
import { checkInBSF } from '../../helper/attendance';

const InstitutesTable = ({ institutesData, registrationType }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredInstitutesData = institutesData.filter((user) => {
    const searchTerm = searchQuery?.toLowerCase();

    return categoryMapping
      .find((map) => map.label.toLowerCase() === registrationType.toLowerCase())
      .fields.some((field) => {
        const fieldValue = user[field.name]?.toString()?.toLowerCase();
        return fieldValue && fieldValue.includes(searchTerm);
      });
  });

  const handleSearchChange = (e) => {
    setCurrentPage(1);

    setSearchQuery(e.target.value);
  };

  const totalPages = Math.ceil(filteredInstitutesData.length / 10);

  const currentData =
    registrationType.toLowerCase() === 'visitors'
      ? filteredInstitutesData.slice((currentPage - 1) * 10, currentPage * 10)
      : filteredInstitutesData;

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className='w-full h-full'>
      <input
        type='text'
        placeholder='Search...'
        value={searchQuery}
        onChange={handleSearchChange}
        className='w-full px-3 py-2 mb-4 border rounded-md focus:outline-none focus:ring focus:border-blue-300'
      />

      <Table.Root size='3' variant='surface' className='w-full'>
        <Table.Header>
          <Table.Row>
            {categoryMapping
              .find(
                (map) =>
                  map.label.toLowerCase() === registrationType.toLowerCase()
              )
              ?.fields.map((field, index) => (
                <Table.ColumnHeaderCell key={index} className='text-sm'>
                  {field.label}
                </Table.ColumnHeaderCell>
              ))}
            <Table.ColumnHeaderCell className='text-sm'>
              QR
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {currentData?.map((user, index) => (
            <Table.Row key={index}>
              {categoryMapping
                .find(
                  (map) =>
                    map.label.toLowerCase() === registrationType.toLowerCase()
                )
                .fields.map((field, index) => (
                  <Table.Cell key={index} className='text-sm'>
                    {field.name === 'checkInTime' ? (
                      new Date(user?.[`${field.name}`])?.getTime() ? (
                        new Date(user?.[`${field.name}`])?.toLocaleString()
                      ) : (
                        '--'
                      )
                    ) : field.name === 'checkedIn' ? (
                      <RadixButton
                        disabled={user?.checkedIn}
                        className='w-10 h-6'
                        variant='outline'
                        onClick={async () => {
                          if (user.checkedIn) {
                            toast.success('User already checked in');
                            return;
                          }
                          try {
                            var response = await checkInBSF(user?.hashValue);
                            if (!response.status)
                              response = await checkInBSF(
                                user?.hashValue,
                                'coaches'
                              );

                            if (!response.status)
                              throw new Error(response.message);
                            if (response.data.checkedIn)
                              throw new Error('User already checked in');
                            toast.success(response.message);
                          } catch (error) {
                            toast.error('Something went wrong');
                            console.error(error);
                          }
                        }}
                      >
                        {user?.checkedIn && <Check color='green' />}
                      </RadixButton>
                    ) : (
                      <Link to={user.institute} key={index}>
                        {user?.[`${field.name}`]?.toString() ?? '--'}
                      </Link>
                    )}
                  </Table.Cell>
                ))}
              <Table.Cell className='text-sm'>
                {user?.imageUrl ? (
                  <Link
                    rel='noreferrer'
                    target='_blank'
                    className='text-blue-600'
                    alt='qr'
                    to={user?.imageUrl}
                  >
                    Download
                  </Link>
                ) : (
                  'No QR'
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Root>

      <Flex
        direction='row'
        gap={{
          initial: '2',
          md: '4',
        }}
        align='center'
        justify='center'
        className='mt-4'
      >
        <Button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          title='Previous'
        />
        <span>
          {currentPage} of {totalPages}
        </span>
        <Button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          title='Next'
        />
      </Flex>
    </div>
  );
};

export default InstitutesTable;
