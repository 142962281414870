import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { firestore } from '../services/firebase';
import SportsTable from '../components/bsf/SportsTable';

const BSFInstitutes = () => {
  const currentYear = new Date().getFullYear();

  let localData = [
    {
      id: 'universities',
      title: 'Universities',
      type: 'university',
      dbPath: `registrations/${currentYear}/bsf/institutes/registrations`,
    },
    {
      id: 'schools',
      title: 'Schools',
      type: 'school',
      dbPath: `registrations/${currentYear}/bsf/institutes/registrations`,
    },
  ];
  const { registrationType, institute } = useParams();

  const [institution, setInstitution] = useState([]);
  const dbPath = localData.find(
    (registrationTypeObject) => registrationTypeObject.id === registrationType
  )?.dbPath;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(firestore, dbPath);

        const q = query(usersCollection, where('institute', '==', institute));

        const docs = await getDocs(q);

        setInstitution(docs.docs[0].data());
      } catch (error) {
        console.error('Error occurred while fetching data:', error);
      }
    };

    fetchUsers();
  }, [registrationType, institute, dbPath]);

  return (
    <Container size='4' align='center' className='mb-5 md:mb-9'>
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '6', md: '9' }}
        className='text-center'
      >
        <h1 className='text-xl md:text-3xl font-bold'>
          {institution?.institute}
        </h1>

        {institution?.sports && (
          <SportsTable
            sportsData={institution?.sports}
            institute={institution}
          />
        )}
      </Flex>
    </Container>
  );
};

export default BSFInstitutes;
