import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Flex, Box, AlertDialog, Button } from '@radix-ui/themes';
import { categoryMapping } from '../../constants/bsf';
import { isHash } from '../../helper/helper_functions';
import { checkInBSF, getInstitutionType } from '../../helper/attendance';

function BSFScanner() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [type, setType] = useState('Visitors');

  const handleScan = async (data) => {
    const qrValue = data[0].rawValue;
    setUserDetails(null);
    setShowAlert(false);
    try {
      if (isHash(qrValue)) {
        var response = await checkInBSF(qrValue);
        if (!response.status) {
          response = await checkInBSF(qrValue, 'coaches');
          setType('Coaches');
        }
        if (!response.status) throw new Error(response.message);
        if (response.data.checkedIn) {
          toast.error('User already checked in');
        } else {
          toast.success(response.message);
          setUserDetails(response.updatedDoc);
          setShowAlert(true);
        }
      } else {
        const institutionType = await getInstitutionType(qrValue);
        navigate(`/bsf/${institutionType}/${data[0].rawValue}`);
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    } finally {
      setType('Visitors');
    }
  };

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      gap='5'
      width='100%'
      height='100%'
    >
      <Box className='w-full md:w-[50%]'>
        <Scanner
          styles={{
            container: {
              border: 'none',
            },
            finderBorder: 10,
          }}
          scanDelay={3000}
          allowMultiple={true}
          onScan={handleScan}
          onError={console.error}
        />
      </Box>

      <AlertDialog.Root open={showAlert}>
        <AlertDialog.Content className='w-auto p-10 flex flex-col align-center gap-5'>
          <AlertDialog.Title className='text-center'>Details</AlertDialog.Title>
          <AlertDialog.Description asChild>
            <Flex direction='column' align='start' gap='5'>
              {categoryMapping
                .find((map) => map.label === type)
                ?.fields.map((field, index) => (
                  <div key={index} className='text-sm'>
                    <strong>{field.label}</strong>:{' '}
                    {userDetails?.[`${field.name}`]?.toString()}
                  </div>
                ))}
            </Flex>
          </AlertDialog.Description>
          <Flex gap='3' mt='5' justify='center'>
            <AlertDialog.Cancel>
              <Button
                variant='solid'
                onClick={() => setShowAlert(false)}
                className='text-black px-9 bg-[#fdfdfd] rounded-lg cursor-pointer'
              >
                Close
              </Button>
            </AlertDialog.Cancel>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Flex>
  );
}

export default BSFScanner;
