import { useEffect, useState } from 'react';
import { Card, Grid, Flex } from '@radix-ui/themes';

import Chart from './Chart';
import RegistrationTypesTable from './RegistrationTypesTable';

const Dashboard = ({
  universitiesData,
  schoolsData,
  visitorsData,
  coachesData,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let localData = [
      {
        id: 'universities',
        title: 'Universities',
        registered: universitiesData.length,
      },
      {
        id: 'schools',
        title: 'Schools',
        registered: schoolsData.length,
      },
      {
        id: 'visitors',
        title: 'Visitors',
        registered: visitorsData.length,
        attended: visitorsData.filter((visitor) => visitor?.checkedIn).length,
      },
      {
        id: 'coaches',
        title: 'Coaches',
        registered: coachesData.length,
        attended: coachesData.filter((coach) => coach?.checkedIn).length,
      },
    ];
    setData(localData);
  }, [universitiesData, schoolsData, visitorsData, coachesData]);

  return (
    <Flex
      direction='column'
      align='center'
      justify='center'
      width='100%'
      height='100%'
      gap={{ initial: '3', md: '5' }}
    >
      <Grid
        columns={{ initial: '1', md: '4' }}
        align='ceter'
        justify='center'
        gap={{ initial: '3', md: '5' }}
        width='100%'
      >
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Universities</p>
          <p className='text-base md:text-lg font-semibold'>
            {data.filter((data) => data.id === 'universities')[0]?.registered}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Schools</p>
          <p className='text-base md:text-lg font-semibold'>
            {data.filter((data) => data.id === 'schools')[0]?.registered}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Visitors</p>
          <p className='text-base md:text-lg font-semibold'>
            {data.filter((data) => data.id === 'visitors')[0]?.registered}
          </p>
        </Card>
        <Card variant='surface' className='p-4'>
          <p className='text-lg md:text-xl font-bold'>Total Coaches</p>
          <p className='text-base md:text-lg font-semibold'>
            {data.filter((data) => data.id === 'coaches')[0]?.registered}
          </p>
        </Card>
      </Grid>

      <Chart data={data} />

      <RegistrationTypesTable data={data} />
    </Flex>
  );
};

export default Dashboard;
